import React, { useEffect, useState } from 'react';
import './style.css';
import Headers from '../../components/header/header';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MessageNotification from '../../components/message_notification/messageNotification';
import ActiveContractsTable from '../../components/active_contracts_table/activeContractsTable';
import contact_img from '../../assets/img/contact_map.png';
import logaction_icon_img from '../../assets/img/material-location-on.svg';
import clock_icon_img from '../../assets/img/awesome-clock.svg';
import fb_icon_img from '../../assets/img/facebook_icon.svg';
import UpdateProfileModal from '../../components/modals/update_profile_modal';
import MessageNotificationModal from '../../components/modals/message_notification_modal';
import loader from '../../assets/loaderPantsat.svg';
import BookMeetingModal from '../../components/modals/book_meeting_modal';
import { Link } from 'react-router-dom';
import BuybackYourPriceModal from '../../components/modals/buyback_your_price_modal';
import messenger_icon from '../../assets/img/messenger-icon.png';
import messenger_profile_img from '../../assets/img/profile-messenger-img.png';
import messenger_close_icon from '../../assets/img/messenger-close.svg';
import axios from 'axios';
import { map } from 'react-bootstrap/ElementChildren';
import config from '../../../utils/config';
import { contractIsOnRecurring, formatDKK } from '../../../utils/common';
import ProlongContractModal from '../../components/modals/prolong_contract_modal';
import { FormattedMessage } from 'react-intl';
import UpdatePasswordModal from '../../components/modals/update_password';

function Contracts() {
    let [dataUser, setDataUser] = useState({});
    const [key, setKey] = useState('contractOverview');
    const [activeTab, setAtciveTab] = useState(false);
    const [list, setUpdateList] = useState([]);
    let [toggleModal, setToggleModal] = useState(false);
    let [toggleModal2, setToggleModal2] = useState(false);
    let [toggleModal3, setToggleModal3] = useState(false);
    let [toggleModal4, setToggleModal4] = useState(false);
    let [toggleModalOwedAmount, setToggleModalOwedAmount] = useState(false);
    let [toggleModalChangePassword, setToggleModalChangePassword] = useState(false);
    let [itemIndex, setItemIndex] = useState(0);
    let [buybackPriceTotal, setBuybackPriceTotal] = useState();
    let [prolongingPriceTotal, setProlongingPriceTotal] = useState();
    let [owedAmountTotal, setOwedAmountTotal] = useState();
    let [contractsNotOnRecurring, setContractsNotOnRecurring] = useState();
    let [contractsNotOnRecurringProlonging, setContractsNotOnRecurringProlonging] = useState();
    let [contractsExpired, setContractsExpired] = useState();

    let [pageloader, setPageloader] = useState(true);

    const handleRemoveMessage = (idx) => {
        let arr = [];
        list.forEach((item, index) => {
            if (idx != item.id) {
                arr.push(item);
            }
        });
        setUpdateList(arr);
        let idxString = idx.toString();

        axios
            .post(`${config.baseUrl}/customerportal/toggleMessageVisibility`, {
                messageId: idxString,
            })
            .catch((error) => {
                console.log('error', error);
                // setError(error.response.data.message)
            });
    };
    let toggleSwitch = (k) => {
        if (k === 'contractOverview') {
            setAtciveTab(false);
        } else if (k === 'contactUs') {
            setAtciveTab(true);
        }
    };

    let changeDataUser = (data) => {
        setDataUser(data);
        setUpdateList(data.messages.filter((m) => m.show));
        setPageloader(false);
    };

    const logOut = () => {
        const url = `${config.baseUrl}/customerportal/logout`;
        axios
            .post(url)
            .then((response) => {
                window.location.href = '/';
            })
            .catch((error) => {
                console.log('error', error);
                if (error?.response?.status === 403) {
                    alert('Noget gik galt. Prøv at logge ind igen.');
                    window.location.href = '/';
                } else {
                    alert(`Noget gik galt. (${error.response.data?.message}) Prøv venligst igen.`);
                }
                // setError(error.response.data.message)
            })
            .then(() => {
                changeDataUser({ messages: [] });
            });
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;
        const url = `${config.baseUrl}/customerportal`;
        axios
            .get(url)
            .then((response) => {
                let buybackPriceArr = [];
                let prolongPriceArr = [];
                let owedAmountArr = [];

                const mapped = response.data.pantsatContracts?.map((contract, index) => {
                    if (contract.customerPortalActive) {
                        let buybackPrice = contract.buybackPrice;
                        let prolongPrice = contract.monthlyPayment;
                        let owedAmount = contract.owedAmount || contract.owedAmountAllActive;
                        if (buybackPrice !== undefined) {
                            buybackPriceArr.push(buybackPrice);
                        }
                        if (prolongPrice !== undefined) {
                            prolongPriceArr.push(prolongPrice);
                        }
                        if (owedAmount !== undefined) {
                            owedAmountArr.push(owedAmount);
                        }
                        contract.isOnRecurring = contractIsOnRecurring(contract);
                        return contract;
                    }
                });
                const notOnRecurring = mapped.filter((p) => !p.isOnRecurring);

                setBuybackPriceTotal(buybackPriceArr.reduce((a, b) => a + b, 0));
                setProlongingPriceTotal(prolongPriceArr.reduce((a, b) => a + b, 0));
                setOwedAmountTotal(owedAmountArr.reduce((a, b) => a + b, 0));
                setContractsNotOnRecurring(notOnRecurring);
                setContractsNotOnRecurringProlonging(notOnRecurring.reduce((acc, p) => acc + (p.monthlyPayment || 0), 0));
                setContractsExpired(mapped.filter((p) => p.daysUntilRepurchase < 0));
                changeDataUser(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                if (error.response?.status === 403) {
                    alert('Noget gik galt. Prøv at logge ind igen.');
                    window.location.href = '/';
                } else {
                    alert('Noget gik galt. Prøv venligst igen.');
                }
                // setError(error.response.data.message)
            });
    }, []);

    return (
        <main className=" bg-main position-relative">
            {pageloader ? (
                <div className="loader">
                    <img src={loader} alt="loader" />
                </div>
            ) : (
                <div>
                    <Headers />
                    <section className="">
                        <div id="logout">
                            <button className={`btn btn-white`} onClick={logOut}>
                                <FormattedMessage id="logOut" defaultMessage="Log out" />
                            </button>
                        </div>
                        <div className="container-fluid fit-section  d-flex justify-content-center align-items-center">
                            <div className="bg-white w-100 border-radius8 overflow-hidden contract-contact-tabs mb-3">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => {
                                        setKey(k);
                                        toggleSwitch(k);
                                    }}
                                >
                                    <Tab eventKey="contractOverview" title="Kontrakt overblik">
                                        <div className="tab-container">
                                            {list.length > 0 &&
                                                list.map((item, idx) => {
                                                    return (
                                                        <MessageNotification
                                                            key={idx}
                                                            persistent={item.persistent}
                                                            message={item.message}
                                                            url={item.url}
                                                            linkName={item.linkName}
                                                            onPressed={() => {
                                                                setToggleModal2(true);
                                                                setItemIndex(idx);
                                                            }}
                                                            onPress={handleRemoveMessage}
                                                            id={item.id}
                                                        />
                                                    );
                                                })}
                                            <div className="totals">
                                                <div className="total total-owed">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.owedAmount.title" defaultMessage="Owed amount" />
                                                    </div>
                                                    <div className="price mb-0">{formatDKK(owedAmountTotal, 0)}</div>
                                                    <div className="small mb-3">
                                                        <FormattedMessage
                                                            id="contracts.owedAmount.description"
                                                            defaultMessage="is the total amount which needs to be payed now to get all your contracts up to date"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`btn btn-gold ${owedAmountTotal ? '' : 'd-none'}`}
                                                        onClick={() => setToggleModalOwedAmount(!toggleModalOwedAmount)}
                                                    >
                                                        {' '}
                                                        <FormattedMessage id="payNow" defaultMessage="Pay now" />
                                                    </div>
                                                </div>
                                                <div className="total total-prolonging">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.monthlyPrice" defaultMessage="Monthly price" />
                                                    </div>
                                                    <div className="price mb-0">{formatDKK(prolongingPriceTotal, 0)}</div>
                                                    <div className="small mb-3">
                                                        {formatDKK(contractsNotOnRecurringProlonging, 0)}{' '}
                                                        <FormattedMessage
                                                            id="contracts.doesntProlong"
                                                            defaultMessage="doesn't prolong automatically"
                                                        />
                                                    </div>
                                                    {/* <div
                                                        className={`btn btn-gold ${contractsNotOnRecurring.length ? '' : 'd-none'}`}
                                                        onClick={() => setToggleModal4(!toggleModal4)}
                                                    >
                                                        {' '}
                                                        <FormattedMessage id="contracts.prolongManual" defaultMessage="Prolong manual contracts" /> (
                                                        {contractsNotOnRecurring.length})
                                                    </div> */}
                                                </div>
                                                <div className="total total-buyback">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.totalBuyback" defaultMessage="Total buyback price" />
                                                    </div>
                                                    <div className="price">{formatDKK(buybackPriceTotal, 0)}</div>
                                                    <button className="btn btn-gold" onClick={() => setToggleModal3(!toggleModal3)}>
                                                        <FormattedMessage id="contracts.buybackAll" defaultMessage="Buyback all" />
                                                    </button>
                                                </div>
                                                <div className="total book-meeting">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.bookMeeting" defaultMessage="Book meeting" />
                                                    </div>
                                                    <div className="text">
                                                        <FormattedMessage
                                                            id="contracts.meeting"
                                                            defaultMessage="If you wish to drop down our store, please book a meeting below"
                                                        />
                                                    </div>
                                                    <a
                                                        href={encodeURI(
                                                            `https://nofipa.appointlet.com/?email=${dataUser.email}&field__telefonnummer=${dataUser.phone}`
                                                        )}
                                                        target="_blank"
                                                        className="btn btn-gold"
                                                        rel="noreferrer"
                                                    >
                                                        Book
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="title-contract">
                                                <FormattedMessage id="contracts.contactInformation" defaultMessage="Contact Information" />
                                            </div>
                                            <div className="account-information d-flex align-items-center mg-b-20">
                                                <div className="center mr-auto d-flex align-items-center">
                                                    <div className="phone info">
                                                        <span className="title">
                                                            <FormattedMessage id="phone" defaultMessage="Telefonnummer" />:
                                                        </span>
                                                        <span className="item">{dataUser.phone}</span>
                                                    </div>
                                                    <div className="mail info">
                                                        <span className="title">Email:</span>
                                                        <span className="item">{dataUser.email}</span>
                                                    </div>
                                                </div>
                                                <div className="btn btn-black-cust py-2" onClick={() => setToggleModal(!toggleModal)}>
                                                    <FormattedMessage id="update" defaultMessage="Update" />
                                                </div>
                                                <div
                                                    className="btn btn-black-cust py-2 mt-2 mt-sm-0 ml-sm-2"
                                                    onClick={() => setToggleModalChangePassword(!toggleModalChangePassword)}
                                                >
                                                    <FormattedMessage id="changePassword" defaultMessage="Change password" />
                                                </div>
                                            </div>{' '}
                                            <div className="title-contract">
                                                <FormattedMessage id="contracts.activeContracts" defaultMessage="Active contracts" />
                                            </div>
                                            <ActiveContractsTable onGetData={dataUser} />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="contactUs" title="Kontakt">
                                        <div className="contactUs two-grid tab-container position-relative">
                                            <div className="z-index-1">
                                                <div className="mg-b-60 mg-t-26">
                                                    <h2 className="color-gold mg-b-20">
                                                        <FormattedMessage id="shops" defaultMessage="Departments" />
                                                    </h2>
                                                    <p className="main-color ft-sz-20">
                                                        <FormattedMessage
                                                            id="shopsInto"
                                                            defaultMessage="We are happy to welcome you into our stores on the following adresses"
                                                        />
                                                    </p>
                                                </div>
                                                <div className="max-475">
                                                    <a
                                                        href="https://goo.gl/maps/EcuyjHoYJeYWr2WY6"
                                                        className="color-gold mg-b-40 d-flex align-items-start"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={logaction_icon_img} className="mr-4" alt="location" />
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                            København - H.C. Andersens Boulevard 38, st. tv., 1553 København V
                                                        </span>
                                                    </a>
                                                    <div className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start">
                                                        <img src={clock_icon_img} className="mr-4" alt="location" />
                                                        <span>
                                                            <FormattedMessage
                                                                id="openingHours"
                                                                defaultMessage="Opening hours: Monday-Thursdag 10:00-18:00* Friday 10:00-17:00*"
                                                            />
                                                        </span>
                                                    </div>
                                                    <a
                                                        href="https://goo.gl/maps/u9YB8tvvNBYTDEbW7"
                                                        className="color-gold mg-b-40 d-flex align-items-start"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={logaction_icon_img} className="mr-4" alt="location" />
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                            Østergade 33, st. th., 8000 Aarhus C
                                                        </span>
                                                    </a>
                                                    <div to="#" className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start">
                                                        <img src={clock_icon_img} className="mr-4" alt="location" />
                                                        <span>
                                                            <FormattedMessage
                                                                id="openingHoursAArhus"
                                                                defaultMessage="Opening hours: Monday-Thursdag 10:00-18:00* Friday 10:00-15:30*"
                                                            />
                                                        </span>
                                                    </div>
                                                    <a
                                                        href="tel:71 74 30 45"
                                                        className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start"
                                                    >
                                                        <span className="ft-sz-20 main-color font-weight-bold bold-family mr-4">
                                                            <FormattedMessage id="phone" defaultMessage="Phone number" />:{' '}
                                                        </span>
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">71 74 30 45</span>
                                                    </a>
                                                    <a
                                                        href="mailto:info@pantsat.dk"
                                                        className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start"
                                                    >
                                                        <span className="ft-sz-20 main-color font-weight-bold bold-family mr-4">E-mail: </span>
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">info@pantsat.dk</span>
                                                    </a>
                                                    <a
                                                        href="https://www.facebook.com/pantsat.dk/"
                                                        className="d-block mg-b-40"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={fb_icon_img} alt="Facebook" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="img img-contact">
                                                <img src={contact_img} alt="" />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </section>
                    {/*modals*/}

                    <UpdateProfileModal
                        title={'Kontakt information '}
                        onPress={setToggleModal}
                        show={toggleModal}
                        currentPhone={dataUser.phone}
                        currentEmail={dataUser.email}
                    />
                    {/*<BookMeetingModal title={"Book a meeting"} onPress ={setToggleModal7} show={toggleModal7}/>*/}
                    <MessageNotificationModal title={''} message={list[itemIndex]?.message} onPress={setToggleModal2} show={toggleModal2} />
                    <BuybackYourPriceModal
                        title={'Tilbagekøb dine kontrakter'}
                        onPress={setToggleModal3}
                        show={toggleModal3}
                        buybackPrice={formatDKK(buybackPriceTotal)}
                        contractId={dataUser.customerId}
                        cardAmount={buybackPriceTotal * 1.02}
                    />
                    <ProlongContractModal
                        title={'Forlæng dine kontrakter'}
                        onPress={setToggleModal4}
                        show={toggleModal4}
                        monthlyPayment={formatDKK(contractsNotOnRecurringProlonging)}
                        contractId={dataUser.customerId}
                        cardAmount={Math.round(contractsNotOnRecurringProlonging * 1.02 * 100) / 100}
                        onRecurring={dataUser.pantsatContracts.length - contractsNotOnRecurring.length}
                        notOnRecurring={contractsNotOnRecurring.length}
                    />
                    <ProlongContractModal
                        title={'Betal skyldigt beløb'}
                        onPress={setToggleModalOwedAmount}
                        show={toggleModalOwedAmount}
                        monthlyPayment={formatDKK(owedAmountTotal)}
                        contractId={dataUser.customerId}
                        cardAmount={Math.round(owedAmountTotal * 1.02 * 100) / 100}
                        onRecurring={0}
                        notOnRecurring={0}
                    />
                    <UpdatePasswordModal
                        title={'Skift kodeord'}
                        onPress={setToggleModalChangePassword}
                        show={toggleModalChangePassword}
                        email={dataUser.email}
                    />
                    {/*modals end*/}
                </div>
            )}
        </main>
    );
}

export default Contracts;
